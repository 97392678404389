<template>
  <!-- Render MeetJitsi only if the provider is 'jitsi' -->
  <div>
    <component
      v-if="roomName !== 'end' && meetComponent"
      :is="meetComponent"
      :room-name="roomName"
      :metadata="metadata"
    />
    <div v-if="roomName === 'end'" class="container row col-12">
      <div class="card">
        <!-- Header with Gradient Background -->
        <div class="card-header">
          <h1>Atendimento Realizado</h1>
        </div>
        <!-- Optional Image Section (if your design includes an image) -->
        <!-- <img src="your-image-url.jpg" alt="Meeting Ended" /> -->
        <!-- Body Content -->
        <div class="card-body">
          <p>Sua reunião foi concluída com sucesso. Obrigado por participar.</p>
        </div>
        <!-- Footer with Action Button -->
        <div class="card-footer">
          <button
            class="btn"
            type="button"
            onclick="window.location.href='https://wellon.digital/sobre-nos'"
          >
            Retornar ao início
          </button>
        </div>
      </div>
    </div>
    <v-overlay :value="exibirLoading" :absolute="true">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MeetJitsi from "@/view/components/MeetJitsi.vue";

export default {
  name: "MeetRoom",
  components: { MeetJitsi },
  props: {
    roomName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      exibirLoading: true,
      provider: null,
      metadata: null,
      meetComponent: null
    };
  },
  mounted() {
    try {
      if (this.roomName === "end") {
        this.exibirLoading = false;
        return;
      }
      this.loadMeetScript().then((response) => {
        this.provider = response?.provider; // Safely assign
        this.metadata = response;
        this.meetComponent = this.provider === "jitsi" ? MeetJitsi : null;
        this.exibirLoading = false;
      });
    } catch (error) {
      console.error("Failed to load Meet script:", error);
      this.exibirLoading = false;
      this.meetComponent = null;
    }
  },
  methods: {
    async loadMeetScript() {
      try {
        const response = await ApiService.get(
          `/meet/${this.roomName}/metadata`,
          "",
          true,
          {
            baseURL: process.env.VUE_APP_COMUNICANTE_API
          }
        );
        return response?.data?.data;
      } catch (error) {
        this.exibirLoading = false;
        this.meetComponent = null;
        // Throw the error so that mounted() can handle it in its catch block
        throw error;
      }
    }
  }
};
</script>

<style scoped>
/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Helvetica Neue", Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.6;
}

.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.card {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.card-header {
  background: linear-gradient(135deg, #462181, #ff7f00);
  padding: 30px 20px;
  color: #fff;
}

.card-header h1 {
  font-size: 28px;
  font-weight: 600;
}

.card-body {
  padding: 20px;
}

.card-body p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.card-footer {
  padding: 20px;
  background-color: #fafafa;
}

.btn {
  background-color: #462181;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #361567;
}

/* Responsive Image (Optional) */
.card img {
  width: 100%;
  height: auto;
  display: block;
}
</style>
