<template>
  <div class="row col-12">
    <!-- Jitsi container -->
    <div id="jitsi-container" style="width: 100%; height: 100vh"></div>

    <!-- Modal for Private Room Pass Code using vue-js-modal -->
    <modal name="private-room-modal" height="auto" :click-to-close="false">
      <div class="modal-content p-4">
        <h2 class="mb-3">Código de acesso</h2>
        <p>Por favor insira o código de acesso para entrar na reunião:</p>
        <div class="form-group mt-3">
          <input
            type="password"
            class="form-control"
            v-model="passCode"
            placeholder="Insira o código"
            @keyup.enter="submitPassCode"
          />
        </div>
        <div class="modal-actions d-flex justify-content-between mt-4">
          <button class="btn btn-danger text-uppercase" @click="cancelPassCode">
            Cancelar
          </button>
          <button
            class="btn btn-primary text-uppercase"
            @click="submitPassCode"
          >
            Enviar
          </button>
        </div>
        <p v-if="errorMsg" class="text-danger mt-3">{{ errorMsg }}</p>
      </div>
    </modal>

    <v-overlay :value="exibirLoading" :absolute="true">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import redirect from "@/view/pages/painel/oauth/Redirect.vue";

export default {
  name: "MeetJitsi",
  props: {
    roomName: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      jitsi: null,
      exibirLoading: true,
      internalMetadata: this.metadata,
      // Modal-related state
      passCode: "",
      errorMsg: ""
    };
  },
  mounted() {
    this.loadJitsiScript().then(() => {
      this.initializeJitsi();
    });
  },
  beforeDestroy() {
    if (this.jitsi && typeof this.jitsi.dispose === "function") {
      this.jitsi.dispose();
    }
  },
  methods: {
    loadJitsiScript() {
      return new Promise((resolve, reject) => {
        if (document.getElementById("jitsi-script")) {
          return resolve();
        }
        const script = document.createElement("script");
        script.id = "jitsi-script";
        script.src = "https://meet.jit.si/external_api.js";
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    },
    initializeJitsi() {
      // If no metadata was passed in as a prop, fetch it.
      if (!this.internalMetadata) {
        this.getMetadata().then((response) => {
          this.internalMetadata = response.data;
          this.processMetadata(this.internalMetadata);
        });
      } else {
        this.processMetadata(this.internalMetadata);
      }
      this.exibirLoading = false;
    },
    processMetadata(roomMetadata) {
      if (roomMetadata.access_control === "public") {
        // For public rooms, use a default pass code (or your preconfigured value).
        this.fetchRoomConfiguration();
      } else if (roomMetadata.access_control === "private") {
        // For private rooms, show the modal using vue-js-modal.
        this.$modal.show("private-room-modal");
      } else {
        console.log("Unknown access control");
      }
    },
    fetchRoomConfiguration(passOrModCode = "") {
      this.exibirLoading = true;
      const params = passOrModCode ? { passOrModCode } : {};
      ApiService.get("/meet/" + this.roomName, "", true, {
        baseURL: process.env.VUE_APP_COMUNICANTE_API,
        params
      })
        .then((responseRoom) => {
          if (responseRoom.data.status === "success") {
            const roomConfig = responseRoom.data.data;
            const { domain, ...options } = roomConfig;
            // Use the specified parent node, or fall back to the jitsi-container.
            options.parentNode =
              document.getElementById(options.parentNode) ||
              document.getElementById("jitsi-container");
            this.mountJitsiIframe(domain, options);
            // Hide the modal if it's currently shown.
            this.$modal.hide("private-room-modal");
          } else {
            // If the API call returns an error, display an error message.
            this.errorMsg =
              "Código de acesso inválido ou não foi possível obter a configuração da sala.";
            // Keep the modal open so the user can try again.
            this.$modal.show("private-room-modal");
          }
          this.exibirLoading = false;
        })
        .catch((error) => {
          console.error("Failed to fetch room configuration:", error);
          this.errorMsg =
            "Erro ao obter a configuração da sala. Por favor, tente novamente.";
          this.$modal.show("private-room-modal");
        });
    },
    mountJitsiIframe(domain, options) {
      this.exibirLoading = true;
      this.jitsi = new window.JitsiMeetExternalAPI(domain, options);
      this.jitsi.addEventListener("participantJoined", (event) => {
        console.log("Participant joined:", event);
      });
      this.jitsi.addEventListener("readyToClose", () => {
        console.log("Meeting closed");
      });
      this.jitsi.addEventListener("videoConferenceLeft", () => {
        this.jitsi = null;
        this.endMeet();
      });
      this.jitsi.addEventListener("videoConferenceJoined", () => {
        this.exibirLoading = false;
      });
    },
    getMetadata() {
      return ApiService.get("/meet/" + this.roomName + "/metadata", "", true, {
        baseURL: process.env.VUE_APP_COMUNICANTE_API
      });
    },
    endMeet() {
      this.jitsi = null;
      this.$router.push("/meet/end");
    },
    submitPassCode() {
      if (!this.passCode) {
        this.errorMsg = "Pass code cannot be empty.";
        return;
      }
      this.errorMsg = "";
      // Emit the pass code to the parent component for further processing.
      // this.$emit("pass-code-submitted", this.passCode);
      this.fetchRoomConfiguration(this.passCode);
      this.$modal.hide("private-room-modal");
    },
    cancelPassCode() {
      this.$modal.hide("private-room-modal");
      this.$emit("pass-code-cancelled");
      this.endMeet();
    }
  }
};
</script>

<style>
#jitsi-container {
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Styles for the modal content */
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-actions button:first-of-type {
  background-color: #f44336;
  color: white;
}

.modal-actions button:last-of-type {
  background-color: #4caf50;
  color: white;
}
</style>
